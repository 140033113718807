import React from 'react';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';


class OnSendEmailVerificationButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isSent: false };
      }
      
    onSendEmailVerification = () => {
         this.setState({ isSent: true });
        this.props.firebase.doSendEmailVerification()
      };


    render(){
        return(
          <AuthUserContext.Consumer>
            {authUser => authUser ? (
          <div className="wrapper maxWidth">
        <h1>Thank you for registering with us, {authUser.username}</h1>
            <p>
            We’ve sent a confirmation to <strong>{authUser.email}</strong>. Once you’ve confirmed your email you will receive access to browse manuals.
            </p>
            <h2>Having issues?</h2>
            <ol>
              <li>Is the email you used correct? Are you checking the correct email account?</li>
              <li>Check your spam folder for the confirmation email, and be sure to whitelist emails from ceca-mcp.com.</li>
              <li>Your email may be delayed.</li>
            </ol>
            <button
              type="button" class="button smaller"
              onClick={this.onSendEmailVerification}
              disabled={this.state.isSent}
            >
              Re-send confirmation Email
            </button>


          </div>
            ):( <></>)}
          </AuthUserContext.Consumer>
        );
    };
}


export default withFirebase(OnSendEmailVerificationButton);
