import React, { Component } from 'react';
//import { Link, navigate } from 'gatsby';
import { navigate } from 'gatsby';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/pro-light-svg-icons';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import Multilingual from '../../styles/multilingual.module.scss'
import AccountErrors from '../../App/components/AccountErrors';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  changeEmailLang: "Email Address",
  changePassLang: "Password",
  nextLang: "Français",
  engCSS: Multilingual.LanguageInactive,
  FRCSS: Multilingual.LanguageActive,

};

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
      var parent = document.getElementById("rel1");
      var slash = parent.getElementsByClassName('slash')[0];
      var reg = parent.getElementsByClassName('reg')[0];

      reg.style.display = "none";

      document.getElementById('togglePassword').onclick = function(e){
         var x = document.getElementById("password");

         if (x.type === "password") {
           x.type = "text";
           slash.style.display = "none";
           reg.style.display = "block";
         } else {
           x.type = "password";
           slash.style.display = "block";
           reg.style.display = "none";
         }
      }
  }
  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        navigate(ROUTES.INDEX);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onClickEN = event => {
    event.preventDefault();
      this.setState({nextLang: "Français"});
      this.setState({changeEmailLang: "Email Address"});
      this.setState({changePassLang: "Password"});
      this.setState({engCSS: Multilingual.LanguageInactive});
      this.setState({FRCSS: Multilingual.LanguageActive});

    }
    onClickFR = event => {
      event.preventDefault();
      this.setState({nextLang: "English"});
      this.setState({changeEmailLang: "Adresse E-mail"});
      this.setState({changePassLang: "Mot de passe"});
      this.setState({engCSS: Multilingual.LanguageActive });
      this.setState({FRCSS: Multilingual.LanguageInactive });

    }

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
        <div className="wrapper maxWidth">
            <div className="switcher-header">
                <h1>Sign In</h1>
                <div className={Multilingual.LanguageButtons}>
                    <button className={this.state.engCSS} onClick={this.onClickEN}>EN</button> |
                    <button className={this.state.FRCSS} onClick={this.onClickFR}>FR</button>
                </div>
            </div>

          <form id="fullWidthForm" onSubmit={this.onSubmit}>
            <div>
                <label htmlFor="email">{this.state.changeEmailLang}<sup>*</sup></label>
                <input
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  type="text"
                  placeholder="Email Address"
                />
            </div>
            <div className="form-group password-group">
                <label htmlFor="password">{this.state.changePassLang}<sup>*</sup></label>
                <div className="relative" id="rel1">
                    <input
                      name="password"
                      id="password"
                      value={password}
                      onChange={this.onChange}
                      type="password"
                      placeholder="Password"
                    />
                    <div className="position-end">
                        <label htmlFor="togglePassword" className="pointer"><span className="screen-reader">Show Password</span><FontAwesomeIcon className="slash" icon={faEyeSlash} /><FontAwesomeIcon className="reg" icon={faEye} /></label>
                        <input className="hidden" type="checkbox" name="togglePassword" id="togglePassword" />
                    </div>
                </div>
            </div>
            {error && <AccountErrors error={error}/>}
            
            <button disabled={isInvalid} type="submit">
              Log In
            </button>

          </form>
        </div>
    );
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        navigate(ROUTES.INDEX);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Google</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        navigate(ROUTES.INDEX);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Facebook</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInTwitterBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithTwitter()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        navigate(ROUTES.INDEX);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {

          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Twitter</button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignInForm = withFirebase(SignInFormBase);

const SignInGoogle = withFirebase(SignInGoogleBase);

const SignInFacebook = withFirebase(SignInFacebookBase);

const SignInTwitter = withFirebase(SignInTwitterBase);

export default SignInForm;

export { SignInGoogle, SignInFacebook, SignInTwitter };
