import React from 'react';
import { Link } from 'gatsby';
import * as ROUTES from '../../constants/routes';
import errorClass from '../../styles/error.module.scss'
//error codes
//const AUTH_EMAIL_USED = 'auth/email-already-in-use';

const AUTH_WRONG_PASS = 'auth/wrong-password';

const AUTH_NO_USER = 'auth/user-not-found';

class AccountErrors extends React.Component{
    constructor(props){
        super(props);
        this.state={ errorMsg: ''};
        
    }

    componentDidMount(){
        
        
    }

    render(){
        if(this.props.error.code === AUTH_WRONG_PASS){
            return(
             <p className="error">The password entered is not correct. If you need help, you can <Link className={errorClass.errorLink} to={ROUTES.PASSWORD_FORGET}>Reset your Password</Link></p>
            )
        }
            else if (this.props.error.code === AUTH_NO_USER){
                return(
                    <p className="error">We could not locate an account with that email. If you haven’t created an account you can <Link className={errorClass.errorLink} to={ROUTES.SIGN_UP}>Register Here.</Link></p>
                )
            }
        else{
            return(
                <p className="error">{this.props.error.message}</p>
            )
        }
    }
    
}

export default AccountErrors; 