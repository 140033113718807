import React, {Component} from 'react';
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronDoubleDown } from '@fortawesome/pro-light-svg-icons';
import _ from'lodash';
import * as ROUTE from '../../constants/routes';


const INITIAL_STATE = {
    title:'',
    elevatorType: '',
    manufacture: '',
    language: '',
    redicrect: false,
    fromLink: false
};


class FilterInput extends Component {
    constructor(props){
        super(props);
        this.state = {};
        this.dataArray = Object.keys(props.array).map(i => props.array[i]);
        this.fromLink = false;
        if(props.location !== undefined){
            if(props.location.state !== null){
                this.fromLink = true
                this.state={...this.props.location.state};
                this.setState({fromLink: false});

            }
        }
        else{
            this.fromLink = false;
            this.state = { ...INITIAL_STATE }
        }
    };

    removeNull(variable){
        for ( const prop in variable ){
            if (variable[prop] === null || variable[prop] === undefined || variable[prop] === '' ){
                delete variable[prop];
            }
        }
        return variable;

    }

    componentDidMount() {

        if(window.location.href !== process.env.GATSBY_FILTER_CHECK){
            this.setState({redirect: true})
        }
        else{
            this.setState({redirect: false})
        }
        if(this.fromLink){
            this.fromLink = false;
            this.filterFunction();
        }


        var that = this;

        var rollDown = document.querySelector('.rollDown');
        document.getElementById('rollDownLabel').onclick = function(e){
            rollDown.classList.toggle('open');
        };

        var x, i, j, selElmnt, a, b, c, w;//var selLabel;
        x = document.getElementsByClassName("filterGroup");
        // for each filterGroup
        for (i = 0; i < x.length; i++) {
            selElmnt = x[i].getElementsByTagName("select")[0];
            //selLabel = selElmnt.previousElementSibling;
            a = document.createElement("DIV");
            a.setAttribute("class", "select-selected");
            a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;

            if(selElmnt.options[selElmnt.selectedIndex].innerHTML) {
                w = x[i].firstChild;
                w.style.opacity = "0";
                //w.classList.add("hidden");
            }

            x[i].appendChild(a);
            b = document.createElement("DIV");
            b.setAttribute("class", "select-items select-hide");

            for (j = 0; j < selElmnt.length; j++) {
                // For each option in the original select element create a new DIV that will act as an option item:
                c = document.createElement("DIV");
                c.innerHTML = selElmnt.options[j].innerHTML;

                c.addEventListener("click", function(e) {
                    // When an item is clicked, update the original select box, and the selected item:
                    var y, i, k, s, h, u;
                    s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                    u = s.previousElementSibling;
                    h = this.parentNode.previousSibling;
                    u.style.opacity = "1";
                    //u.classList.toggle("hidden");

                    for (i = 0; i < s.length; i++) {
                        if (s.options[i].innerHTML === this.innerHTML) {
                            s.selectedIndex = i;
                            h.innerHTML = this.innerHTML;
                            y = this.parentNode.getElementsByClassName("same-as-selected");
                            for (k = 0; k < y.length; k++) {
                                y[k].removeAttribute("class");
                            }
                            if(this.innerHTML !== '') {u.style.opacity = "0";}

                            this.setAttribute("class", "same-as-selected");

                            that.setState({ [s.id]: this.innerHTML });

                            break;
                        }
                    }
                    h.click();
                });
                b.appendChild(c);
            }
            x[i].appendChild(b);

            a.addEventListener("click", function(e) {

                e.stopPropagation();
                closeAllSelect(this);
                this.nextSibling.classList.toggle("select-hide");
                this.classList.toggle("select-arrow-active");
            });

        }


        function closeAllSelect(elmnt) {

            var x, y, i, arrNo = [];
            x = document.getElementsByClassName("select-items");
            y = document.getElementsByClassName("select-selected");
            for (i = 0; i < y.length; i++) {
                if (elmnt === y[i]) {
                    arrNo.push(i)
                } else {
                    y[i].classList.remove("select-arrow-active");
                }
            }
            for (i = 0; i < x.length; i++) {
                if (arrNo.indexOf(i)) {
                    x[i].classList.add("select-hide");
                }
            }
        }

        document.addEventListener("click", closeAllSelect);

         /* show labels if clear filter button clicked */
         var clearFilter = document.getElementById('clearFilter');
         clearFilter.onclick = function() {

            //  var filterM = document.getElementById('filterM');
            //  if ( filterM ) {
            //      filterM.outerHTML = '';
            //  }

             x = document.getElementsByClassName("filterGroup");
             var group;
             for (i = 0; i < x.length; i++) {
                 group = x[i];
                 group.getElementsByTagName('label')[0].style.opacity = "1";
                 group.getElementsByClassName('select-items')[0].firstChild.click();

             }
         };
        this.addFilteredBy();
    }


    addFilteredBy(){

        /* add filtering by item to be toggled off */
        var x = document.getElementsByClassName("filterGroup");

        var node = document.createElement('span');
        node.setAttribute('id', 'filterM');
        var selectedText = document.getElementsByClassName('select-selected')[0];
        var textnode = document.createTextNode('Manufacturer: '+selectedText.innerHTML);
        var filterM = document.getElementById('filterM');
        if( filterM ) {
            filterM.remove();
        }
        if(selectedText.innerHTML !== '') {
            node.appendChild(textnode);
            document.getElementById('filteringBy').appendChild(node);
        } else {
            filterM = document.getElementById('filterM');
            if( filterM ) {
                filterM.remove();
            }
        }
        filterM = document.getElementById('filterM');
        if(typeof(filterM) !== 'undefined' && filterM !== null){
            filterM.onclick = () => {
                if ( filterM ) {
                    filterM.outerHTML = '';
                    this.setState({manufacture: ''})
                    var group = x[0];
                    group.getElementsByTagName('label')[0].style.opacity = "1";
                    group.getElementsByClassName('select-items')[0].firstChild.click();
                    this.filterFunction();

                }
            };
        }

        /* add filtering by item 2 to be toggled off */
        var node1 = document.createElement('span');
        node1.setAttribute('id', 'filterT');
        var selectedText1 = document.getElementsByClassName('select-selected')[1];
        var textnode1 = document.createTextNode('Type: '+selectedText1.innerHTML);
        var filterT = document.getElementById('filterT');
        if( filterT ) {
            filterT.remove();
       }

        if(selectedText1.innerHTML !== '') {
            node1.appendChild(textnode1);
            document.getElementById('filteringBy').appendChild(node1);
        } else {
            filterT = document.getElementById('filterT');
            if( filterT ) {
                 filterT.remove();
            }
        }
        filterT = document.getElementById('filterT');
        if(typeof(filterT) !== 'undefined' && filterT !== null){
            filterT.onclick = () => {
                if ( filterT ) {
                    filterT.outerHTML = '';
                    this.setState({elevatorType: ''})
                    var group = x[1];
                    group.getElementsByTagName('label')[0].style.opacity = "1";
                    group.getElementsByClassName('select-items')[0].firstChild.click();
                    this.filterFunction();


                }
            };
        }
        /* add filtering by item 3 to be toggled off */

        var node2 = document.createElement('span');
        node2.setAttribute('id', 'filterL');
        var selectedText2 = document.getElementsByClassName('select-selected')[2];
        var textnode2 = document.createTextNode('Language: '+selectedText2.innerHTML);
        var filterL = document.getElementById('filterL');
        if( filterL ) {
            filterL.remove();
        }
        if(selectedText2.innerHTML !== '') {

            node2.appendChild(textnode2);
            document.getElementById('filteringBy').appendChild(node2);
        } else {
            filterL = document.getElementById('filterL');
            if( filterL ) {
                filterL.remove();
            }
        }
        filterL = document.getElementById('filterL');
        if(typeof(filterL) !== 'undefined' && filterL !== null){
            filterL.onclick = () => {
                if ( filterL ) {
                    filterL.outerHTML = '';
                    var group = x[2];
                    group.getElementsByTagName('label')[0].style.opacity = "1";
                    group.getElementsByClassName('select-items')[0].firstChild.click();
                    this.setState({language: ''})
                    this.filterFunction();
                }
            };
        }



    }

    ghostClear(){

        var x = document.getElementsByClassName("filterGroup");
        var group = x[0];
        group.getElementsByClassName('select-items')[0].firstChild.click();

        group = x[1];
        group.getElementsByClassName('select-items')[0].firstChild.click();

        group = x[2];
        group.getElementsByClassName('select-items')[0].firstChild.click();

        var filterM = document.getElementById('filterM');
        if( filterM ) {
            filterM.remove();
        }

        var filterT = document.getElementById('filterT');
        if( filterT ) {
            filterT.remove();
       }

        var filterL = document.getElementById('filterL');
        if( filterL ) {
            filterL.remove();
        }
    }





    filterFunction = () => {

        var options = {title: this.state.title, elevatorType: this.state.elevatorType, manufacture: this.state.manufacture, language: this.state.language}
        options = this.removeNull(options);

        const filterArray = this.dataArray;

        var filterTitle = [];
        var emptyTest = Object.keys(options).length<1;



        if(emptyTest){
            this.props.onFilterChange(this.dataArray);
        }
        else{


        var filteredManufacture = filterArray.filter( function(element){ return element.elevatorManufacturer[0].title === options.manufacture} );

       
        var filteredType = filterArray.filter( function(element){ return element.elevatorType[0].title === options.elevatorType} );
       
         filteredType = filteredType.filter( function(element){ return element !== undefined} );




        var filterLangue = filterArray.filter( function(element){ return element.language[0].title === options.language} );
  

        if(options.title !== '' && options.title !== undefined){
             filterTitle = filterArray.filter( element => element.title.toUpperCase().includes(options.title.toUpperCase()) );
        }

    

        var results = [filteredType, filterLangue, filterTitle,filteredManufacture];
        results = results.filter( function(element){ return element.length} );
        const fullFilter = _.intersection.apply(_, results);



        this.props.onFilterChange(fullFilter);
        }
    }

    onSubmit = event => {
        document.querySelector('.rollDown').classList.toggle('open');
        this.filterFunction();
        event.preventDefault();
        this.addFilteredBy();

    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });

    };

    clearFilter = () => {
        this.setState({
            title:'',
            elevatorType: '',
            manufacture: '',
            language: '',
        })
        this.props.onFilterChange(this.dataArray);
        this.addFilteredBy();
        this.ghostClear();

    }

    onClearFilter = event => {

        this.clearFilter();

        event.preventDefault();
    }
    render(){
        const {
            title,
            elevatorType,
            manufacture,
            language,
            redirect
        } = this.state;
        return (
             <>
                <div id="filtering">
                    <form onSubmit={this.onSubmit}>
                    <div className="form-group password-group">
                        <label htmlFor="title" id="searchLabel">Search Manual Database</label>
                        <div className="relative" id="rel1">
                            <input
                                name='title'
                                value={title}
                                id="title"
                                className="fullInput"
                                onChange={this.onChange}
                                type='text'
                            />
                            <div className="position-end">
                                <label htmlFor="submitSearch" className="pointer"><span className="screen-reader"></span><span class="Search">Search <FontAwesomeIcon className="search" icon={faSearch} /></span></label>
                                {redirect ? <Link to="/manuals" state={{ title: this.state.title, elevatorType: this.state.elevatorType, manufacture: this.state.manufacture, language: this.state.language, fromLink:true }} ><button className="visually-hidden" type="submit" name="submitSearch" id="submitSearch" /></Link> : <button className="visually-hidden" type="submit" name="submitSearch" id="submitSearch" />}
                            </div>
                        </div>
                    </div>
                    <fieldset id="rollDown" className="rollDown">
                        <label id="rollDownLabel" htmlFor="rollDown">Filter Search <FontAwesomeIcon className="doubleArrow" icon={faChevronDoubleDown} /></label>
                        <div>
                            {/* Remove class HideField to display again */}
                        <div className="filterGroup">
                                <label htmlFor="manufacture">Filter Elevator Manufacturers</label>
                                <select id="manufacture" name="manufacture" onBlur={this.onChange} value={manufacture}>
                                    <option value=''></option>
                                    {this.props.manufacture.map((names) => {
                                            return(
                                                <option value={this.manufacture} key={names.title}>{names.title}</option>
                                            )
                                        })}

                                </select>
                            </div>
                             
                            <div className="filterGroup">
                                <label htmlFor="elevatorType">Filter Tag</label>
                                <select id="elevatorType" name="elevatorType" onBlur={this.onChange} value={elevatorType}>
                                    <option value=''></option>
                                    {this.props.eletype.map((names) => {
                                            return(
                                                <option value={this.elevatorType} key={names.title}>{names.title}</option>
                                            )
                                        })}

                                </select>
                            </div>
                            <div className="filterGroup">
                                <label htmlFor="language">Filter by Language</label>
                                <select id="language" name="language" onBlur={this.onChange} value={language}>
                                        <option value =''></option>
                                        {this.props.language.map( (name) =>{
                                            return(
                                                <option value={this.language} key={name.title}>{name.title}</option>
                                            )
                                        })}
                                </select>
                            </div>

                            {redirect ? <Link to={ROUTE.MANUALS} state={{ title: this.state.title, elevatorType: this.state.elevatorType, manufacture: this.state.manufacture, language: this.state.language, fromLink:true }} ><button type="submit">Apply Filters</button></Link> : <button type="submit">Apply Filters</button>}
                            <button id="clearFilter" onClick={this.clearFilter}> Clear Filter </button>
                            </div>
                        </fieldset>
                    </form>

                </div>
                <div id="filteringBy"></div>
            </>
        )
    }


}

export default FilterInput;
